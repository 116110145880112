.ts-star-rating {
  position: relative;
  font-size: 1.25em;
  display: inline-block;
}

.ts-star-rating-stars {
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.ts-star-rating-stars-fg {
  position: absolute;
  top: 0;
  color: var(--ts-primary-color);
  overflow: hidden;
  // width: 100%;
}

.ts-star-rating-stars-bg {
  color: #a5a5a5;
  width: fit-content;
  .ts-star-rating-star {

  }
}
