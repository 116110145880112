.ts-video-player-ctn {
  position: relative;
  cursor: pointer;
}

.ts-video-player-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  z-index: 100;
}

.ts-video-player-ctn:hover .ts-video-player-controls,
.ts-video-player-ctn:focus-within .ts-video-player-controls,
.ts-video-player-controls.pause {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.ts-video-player-play-pause-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
}

.ts-video-player-play-pause {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100px;
  height: 100px;
  background: #0008;
  transition: opacity 150ms ease-in-out;
  border-radius: 50%;
}

.ts-video-player-play-pause-icon {
  color: white;
  font-size: 100px;
  position: absolute;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ts-video-player-play-pause-icon.active {
  opacity: 1;
}

.ts-video-player-play { // Looks weird cause math and unit circle -> tan^(-1)(m)=theta
  padding-left: 7px;
}

.ts-video-player-replay {
  padding-bottom: 9px;
}

.ts-video-player-audio-ctn {
  display: flex;
  justify-content: start;
  align-items: center;
  position: absolute;
  left: 24px;
  top: 125px; // 100px input + 25px icon font
  transform: rotate(-90deg);
  transform-origin: center left;
}

.ts-video-player-audio-icon {
  color: white;
  font-size: 25px;
  transform: rotate(90deg);
  margin-left: 5px;
}

.ts-video-player-audio {
  width: 100px;
}

.ts-video-player-audio-input {
  
}

.ts-video-player-timeline-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
}

.ts-video-player-timeline {
  width: 90%;
}

.ts-video-player-timeline-input {
  height: 5px;
  transition: width 0.1s ease-in-out;
  transition: height 0.1s ease-in-out;
}

.ts-video-player-timeline-ctn:hover .ts-video-player-timeline-input {
  height: 15px;
  transition: width 0.1s ease-in-out;
  transition: height 0.1s ease-in-out;
}

.ts-video-player-timeline-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px !important;
  height: 10px !important;
  background: var(--ts-primary-color);
  cursor: pointer;
  border-radius: 50%;
  transition: width 0.1s ease-in-out;
  transition: height 0.1s ease-in-out;
}
.ts-video-player-timeline-input::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px !important;
  height: 10px !important;
  background: var(--ts-primary-color);
  cursor: pointer;
  border-radius: 50%;
  transition: width 0.1s ease-in-out;
  transition: height 0.1s ease-in-out;
}

.ts-video-player-timeline-ctn:hover .ts-video-player-timeline-input::-webkit-slider-thumb {
  width: 20px !important;
  height: 20px !important;
}
.ts-video-player-timeline-ctn:hover .ts-video-player-timeline-input::-moz-range-thumb {
  width: 20px !important;
  height: 20px !important;
}

.ts-video-player {
  width: 100%;
  border-radius: 8px;
}
