.ts-rich-text-editor {
  height: 300px;
  overflow-y: auto;
  text-align: left;

  /* Make sure the click area for setting focus is big enough */
  & > div {
    height: 280px;
  }

  &.rich-text-container-small {
    height: 100px;
  }

  ul,
  ol {
    li {
      color: var(--style-text-default);
    }
  }

  p,
  li {
    font-size: 14px;
  }
}

.gmd_container.gmd .ts-rich-text-editor {
  ul,
  ol {
    padding-left: 20px;
  }
}

.ts-rich-text-editor-toolbar-container {
  padding: 5px 0;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;

  .toolbar-group:not(:last-child) {
    padding-right: 5px;
    margin-right: 10px;
    border-right: 1px solid #ddd;
  }

  .toolbar-button {
    border: 1px solid #ddd;
    padding: 2px 5px;
    border-radius: 4px;
    margin-right: 5px;
    cursor: pointer;
  }
  .toolbar-button-active {
    background-color: #e1e1e1;
  }
  .emoji-select {
    font-style: normal;
  }
}
