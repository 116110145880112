.tssm-location-card {

  position: relative;

  .tssm-location-card-inner {

    .tssm-location-card-bg {
      border-radius: var(--style-border-radius-lg);
      @extend .tssm-container-shadows;
    }
  }


}

.tssm-location-card-inner {
  display: block;
  margin: 0;
  text-decoration: none;
  color: inherit;
  padding:10px;
  @media only screen and (max-width: $tssm-md-rule) {
    margin: 10px 20px;
  }
}

.tssm-location-card-inner-content {
  text-decoration: none !important;
}

.tssm-location-card-bg {

  width: 100%;
  height: 280px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  filter: brightness(77%);
  -webkit-filter: brightness(77%);
  -moz-filter: brightness(77%);
}

.tssm-location-card-title {
  text-align: center;
  padding: 14px;
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 0px;
  color: white !important;
  text-decoration: none!important;
  text-transform: uppercase;
  position: absolute;
  z-index: 1;
  transform: translate(0, 0px);
  -webkit-transform: translate(0, 0px);
  -moz-transform: translate(0, 0px);
  transition: all 0.25s ease-in-out;
  top: 10px;
  left: 20px;
  @media only screen and (max-width: 720px) {
    top: 20px;
    left: 37px;
  }
}


.tssm-location-card {


  &:hover {
    .tssm-location-card-title {

      transform: translate(17px, 0px);
      -webkit-transform: translate(17px, 0px);
      -moz-transform: translate(17px, 0px);
    }
  }

}
