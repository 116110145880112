.tssm-events {
  width: 100%;
}

.tssm-events-title {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.tssm-events-title-num {
  color: var(--style-color-primary);
  margin-left:10px;
}

.tssm-events-ctn {
  float: left;
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  padding: 0px;
  .tssm-event-card {
    width: 25%;
  }

  @media only screen and (max-width: $tssm-xl-rule) {
    .tssm-event-card {
      width: 50%;
      margin: 0px;
    }
  }

  @media only screen and (max-width: $tssm-md-rule) {
    .tssm-event-card {
      width: 100%;
    }
    .tssm-events-row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }
}



.tssm-events-browse-divider {

}

