@media only screen and (max-width: 768px) {
  .discover-search {
    .section-search {
      transition: all 0.25s ease-in-out;

      .tssm-search-bar-inner {
        position: relative;
        left: 0;
        z-index: 700;
      }
    }
  }


  .tssm-search-bar-inner {

  }
  .discover-search.scrolled {
    .tssm-search-bar-inner {
      position: fixed;
      top: 68px;
      padding: 15px;
      width: 100%;
      max-width: 100%;
    }

    .section-search {
      height: 50px;
    }
  }
}



.tssm-search-bar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.tsp-searchbar-left {
  .tssm-search-bar {
    align-items: flex-start;
  }
}

.tsp-searchbar-center {
  .tssm-search-bar {
    align-items: center;
  }
}

.tssm-search-bar-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  min-width: 340px;
  width:100%;
  background-color: white;
  margin: 2px;
  border: 1px solid #d1d1d1;
  transition: all 0.5s ease;
  position: relative;
}



.tssm-search-bar-fixed {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
}

.tssm-search-bar-absolute {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
}

.tssm-search-bar-inner .tsi-location {
  font-size: 22px;
  color: #d7d7d7;
  position: relative;
  top: -2px;
  padding-left: 14px;
  padding-right: 2px;
}

.tssm-search-bar-inner-search-btn {
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 0;
  padding: 1em 1.4em;
  display: flex;
  font-size: 1em;
  align-items: center;
  justify-content: center;
}

.tssm-search-bar-inner-search-btn .tsi-search {
  color: white;
  font-size: 24px;
}

.tssm-search-bar-inner-divider {
  border: 0px;
  border-right: 1px solid var(--ts-border-color);
  margin: 2px;
  width: 0px;
  height: 30px;
}

.tssm-search-bar-location-in {
  flex-grow: 1;

  min-width: 140px;
  border: none !important;
  outline: none !important;
  padding: 10px;
  &:focus-visible {
    border: none !important;
    outline: none !important;
  }
}

.tssm-search-bar-sel {
  min-width: 60px;
  //color: var(--style-color-primary);
  border: none;
  margin: 0px 2px;

  @media only screen and (max-width: $tssm-md-rule) {
    justify-content: center;
    min-width: 50px;
    display: flex;
    .ts-selector-icon {
      margin-left: 0;
    }
  }

  .ts-selector-title {
    font-size: 12px;
    @media only screen and (max-width: $tssm-md-rule) {
      display: none;
    }
  }
  .ts-selector-items {
    @extend .tssm-container-shadows;
    border-radius: var(--style-border-radius-md);
    width: 100%;
    max-width: 600px;
    flex-wrap: wrap;
    right: -10px;
    top: 47px;
    left: auto;
    opacity: 0;
    padding:15px;

    .ts-selector-item {
      border: 1px solid gray;
      margin-right: 8px;
      float: left;
      border-radius: 8px;
      padding: 4px 10px;
      margin-bottom: 8px;
      transition: all 0.5s ease;
      cursor: pointer;
      p {
        font-size: 12px !important;
      }
    }

    .ts-selector-item:hover {
      background-color: var(--style-color-primary);
      border: 1px solid var(--style-color-primary);
      p {
        color: white !important;
      }
    }
  }
  .ts-selector-items.active {
    visibility: visible;
    opacity: 1;
  }
  .ts-selector-icon {
    color: var(--tssm-text-primary);
    top: 2px;
  }
}

.active.tssm-search-bar-sel {
}

.tssm-search-bar-places.hidden {
  visibility: hidden;
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  top: 100px;
  opacity: 0;
}

.tssm-search-bar-places {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  position: absolute;
  top: 70px;
  z-index: 200;
  padding: 10px;
  border: 0;
  background-color: White;
  @extend .tssm-container-shadows;

  transition: all 0.8s ease-in-out;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transition-delay: 0.7s;
  opacity: 1;
  visibility: visible;
}

.tssm-search-bar-places-item {
  width: calc(100% - 20px);
  flex-grow: 1;
  font-size: 16px;
  padding: 8px 0px;
  cursor: pointer;
  text-align: left;
}

.tssm-search-bar-places-item:hover {
  color: var(--style-color-primary);
}

.search-bar-loading-ctn {
  display: flex;
  justify-content: center;
}

.search-bar-loading {
  max-width: 600px;
  width: 100%;
  padding: 4px;
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 100px;
  margin-top: 8px;

  .search-bar-loading-inner {
    min-width: 320px;
    width: 100%;
    display: flex;
    border-radius: 100px;
    padding: 8px;
    text-align: center;
    justify-content: center;
    color: #c5c5c5;
    background-image: linear-gradient(
        90deg,
        rgba(lightgrey, 0) 0,
        rgba(lightgrey, 0.8) 50%,
        rgba(lightgrey, 0) 100%
      ),
      //animation blur
      var(--title-skeleton),
      //title
      var(--desc-line-skeleton),
      //desc1
      var(--desc-line-skeleton),
      var(--footer-skeleton),
      //footer bar
      var(--card-skeleton) //card
;

    background-size: var(--blur-size), var(--title-width) var(--title-height),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height), 0,
      100% var(--footer-height), 100% 100%;

    background-position: -150% 0,
      //animation
      var(--title-position),
      //title
      var(--desc-line-1-position),
      //desc1
      var(--desc-line-2-position),
      //desc2
      var(--avatar-position),
      //avatar
      var(--footer-position),
      //footer bar
      0 0 //card
;

    background-repeat: no-repeat;
    animation: loading 2s infinite;
  }
}
