.ts-sel {
  position: relative;
  width: fit-content;
  padding: 0.5em;
  cursor: pointer;

  flex-shrink: 0;

  .ts-selector-title-ctn {
    display: flex;
    align-items: center;
  }
}

.ts-selector-icon {
  font-size: 1.1em;
  padding: 8px 8px;
  margin-left: 0;
  margin-right: 10px;
  color: var(--style-color-primary);
}

.ts-selector-items {
  display: none;
  position: absolute;
  max-height: 0px;
  padding: 10px;
  overflow: hidden;
  background-color: white;
  transform: translate(-10px, 25px);
  transition: all 0.5s ease;
  opacity: 0;
  z-index: 6000;
  max-width: 400px;
  visibility: hidden;
  display: block;
  border-radius: var(--style-border-radius-md);
  box-shadow: 1px 0px 21px 0px rgba(0, 0, 0, 0.22) !important;
  -webkit-box-shadow: 1px 0px 21px 0px rgba(0, 0, 0, 0.22) !important;
  -moz-box-shadow: 1px 0px 21px 0px rgba(0, 0, 0, 0.22) !important;
}

.ts-selector-items.active {
  max-height: 1000px;
  opacity: 1;
  transform: translate(-10px, 15px);
  visibility: visible;
  opacity: 1;
}

.ts-selector-items {
  transition: all 0.5s ease;
  cursor: pointer;
  p {
    transition: all 0.5s ease;
  }
}
.ts-selector-title {
}

.ts-selector-item {
  p {
    color: var(--tssm-text-primary) !important;
    font-size: 16px !important;
    margin: 0;
    padding: 8px 0;
  }
}

.ts-selector-item:hover {
  p {
    color: var(--style-color-primary) !important;
  }
}
