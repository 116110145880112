.tssm-suggested-locations-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px;


  .tssm-location-card {
    width: 33%;

  }

  @media only screen and (max-width: $tssm-xl-rule) {
    .tssm-location-card {
      width: 50%;
      margin: 0px;
    }
  }

  @media only screen and (max-width: $tssm-md-rule) {
    .tssm-location-card {
      width: 100%;
    }
    .tssm-locations-row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }
}

.tssm-suggested-locations-title {


}
