/* tssm- prefixes are for this repo only, Thriv-Sports-Booking */
@import-normalize;

/* These ones are used in media queries so can't be css vars :( */
$tssm-sm-rule: 480px;
$tssm-md-rule: 720px;
$tssm-lg-rule: 920px;
$tssm-xl-rule: 1080px;

.tssm {
  color: var(--tssm-text-primary) !important;
}

.tssm a {
  // color: inherit;
  color: default;
}

.tssm-modal-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  padding: 0px;
  margin: 0px;
  font-size: 38px;
}

// settings

.tssm-container-radius {
  border-radius: 15px !important;
}

.tssm-container-shadows {
  box-shadow: 1px 0px 21px 0px rgba(0, 0, 0, 0.22) !important;
  -webkit-box-shadow: 1px 0px 21px 0px rgba(0, 0, 0, 0.22) !important;
  -moz-box-shadow: 1px 0px 21px 0px rgba(0, 0, 0, 0.22) !important;
}

// loading animation

:root {
  --card-padding: 24px;
  --card-height: 340px;
  --card-skeleton: linear-gradient(lightgrey var(--card-height), transparent 0);

  --avatar-size: 32px;
  --avatar-position: var(--card-padding) var(--card-padding);
  --avatar-skeleton: radial-gradient(
    circle 16px at center,
    white 99%,
    transparent 0
  );

  --title-height: 32px;
  --title-width: 200px;
  --title-position: var(--card-padding) 180px;
  --title-skeleton: linear-gradient(white var(--title-height), transparent 0);

  --desc-line-height: 16px;
  --desc-line-skeleton: linear-gradient(
    white var(--desc-line-height),
    transparent 0
  );
  --desc-line-1-width: 230px;
  --desc-line-1-position: var(--card-padding) 242px;
  --desc-line-2-width: 180px;
  --desc-line-2-position: var(--card-padding) 265px;

  --footer-height: 40px;
  --footer-position: 0 calc(var(--card-height) - var(--footer-height));
  --footer-skeleton: linear-gradient(white var(--footer-height), transparent 0);

  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(var(--card-height) - var(--footer-height));
}

/*
 * Card Skeleton for Loading
 */

.loading-cards {
  overflow: auto;

  .loading-card-container {
    float: left;
    width: 25%;
    padding: 5px;
    @media (max-width: 800px) {
      width: 50%;
    }
    @media (max-width: 600px) {
      width: 100%;
      padding: 10px;
    }
  }

  @media (max-width: 800px) {
    .loading-card-container:nth-child(3),
    .loading-card-container:nth-child(4) {
      display: none;
    }
  }
}

.card-loading {
  width: 100%; //demo
  height: var(--card-height);

  &:empty::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);

    background-image: linear-gradient(
        90deg,
        rgba(lightgrey, 0) 0,
        rgba(lightgrey, 0.8) 50%,
        rgba(lightgrey, 0) 100%
      ),
      //animation blur
      var(--title-skeleton),
      //title
      var(--desc-line-skeleton),
      //desc1
      var(--desc-line-skeleton),
      //desc2
      var(--avatar-skeleton),
      //avatar
      var(--footer-skeleton),
      //footer bar
      var(--card-skeleton) //card
;

    background-size: var(--blur-size), var(--title-width) var(--title-height),
      var(--desc-line-1-width) var(--desc-line-height),
      var(--desc-line-2-width) var(--desc-line-height),
      var(--avatar-size) var(--avatar-size), 100% var(--footer-height),
      100% 100%;

    background-position: -150% 0,
      //animation
      var(--title-position),
      //title
      var(--desc-line-1-position),
      //desc1
      var(--desc-line-2-position),
      //desc2
      var(--avatar-position),
      //avatar
      var(--footer-position),
      //footer bar
      0 0 //card
;

    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position: 350% 0, var(--title-position),
      var(--desc-line-1-position), var(--desc-line-2-position),
      var(--avatar-position), var(--footer-position), 0 0;
  }
}

@import './views/contentView.scss';
@import './views/searchResultsView.scss';
@import './views/coachesView.scss';
@import './views/eventsView.scss';
@import './views/suggestedLocationsView.scss';
@import './views/mapPreviewView.scss';
@import './views/mapResultsView.scss';
@import './views/searchBarView.scss';

@import './components/coachCard.scss';
@import './components/coachRow.scss';
@import './components/coachTab.scss';
@import './components/eventCard.scss';
@import './components/eventRow.scss';
@import './components/eventTab.scss';
@import './components/locationCard.scss';

// move to theme file for thriv only

// Thrive Search Landing Banner

.tssm-landing-header {
  background-color: #b41433;
  color: #fff;
  padding-top: 100px;
  position: relative;
  padding-bottom: 160px;
}

.discover-search .tsp-search-marketplace-content-injection-site {
}

#topnav #navigation.nav-mobile-menu-ctn {
  z-index: 30000;
  @media (min-width: 768px) {
    display: none !important;
  }
}

.navbar-marketplace {
  .tssm-search-bar-location-in {
    @media (min-width: 768px) {
      background-color: transparent;
      font-size: 14px;
      min-width: 300px;
    }
  }

  .nav-partner-cta {
    padding: 0;
  }
  .navbar-container-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    #navigation {
      width: 100%;
    }
    .navbar-right,
    .navigation-right {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .tssm-search-bar-inner {
      border-radius: 40px;
      width: 100%;
    }

    .top-user-ava .user-info-span {
      display: none;
    }

    .tsp-applet {
      background-color: transparent !important;
      float: none;
      width: 100%;
    }

    .topbar-left {
      display: flex;
      align-items: center;
      width: 100%;
      @media (max-width: 768px) {
        justify-content: center;
      }
      .logo-inner-text {
        display: none;
      }
    }
  }

  > .container {
    justify-content: center;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .navbar-container-inner {
    width: 100%;
  }

  .tsp-searchbar-navbar-ctn {
    @media (max-width: 768px) {
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .tssm-search-bar-inner {
      @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
      }
    }
    @media (min-width: 768px) {
      position: absolute;
      top: 3px;
      width: auto;
      left: auto;
      right: auto;
      z-index: 1;
    }
  }
}

@media (min-width: 768px) {
  body
    .tsp-application-nosession
    #wrapper.enlarged
    .topbar
    .navbar-marketplace
    .topbar-left.mobile-logo {
    width: 100% !important;
  }
}
