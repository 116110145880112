.section-coaches {
  position: relative;
  padding-top: 100%;

  .tssm-coach-card-inner {
    border-radius: var(--style-border-radius-lg);
    overflow: hidden;
    .tssm-coach-card-content {
      padding: 20px 20px 30px 20px;
    }
  }
}

.tssm-coach-row {
  display: flex;
  border-bottom: 1px solid #dddddd;
  flex-direction: column;
}

.tssm-coach-row-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

.tssm-coach-row-content {
  width: 100%;
  position: relative;
  padding-left: 0px;
  transition: all 0.25s ease-in-out;
}

.tssm-map-results-see-more {
  text-align: center;
  padding: 2em;
}

.tssm-coach-row-content-right {
  display: flex;
  justify-content: flex-start;
  align-items: end;
  flex-direction: column;
  height: 100%;
  @media only screen and (max-width: $tssm-xl-rule) {
  }

  @media only screen and (max-width: $tssm-md-rule) {
    width: 100%;
    flex-direction: column;
    height: auto;
    align-items: start;
    margin-top: 15px;
  }
}

.tssm-coach-row-av-text {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  width: 75%;
  @media only screen and (max-width: $tssm-xl-rule) {
  }

  @media only screen and (max-width: $tssm-md-rule) {
    width: 100%;
    flex-direction: column;
  }

  @media only screen and (max-width: 600px) {
    .ts-link-image-wrap {
      width: 100%;
    }
  }
}

.tssm-coach-row-avatar-ctn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 80px;

  .tssm-coach-card-rating {
    position: absolute;
    background-color: var(--style-color-primary);
    border-radius: 100px;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    color: white;
    margin-bottom: 20px; /* Adjust as needed */
    margin-right: 20px; /* Adjust as needed */
    width: -webkit-max-content;
    svg {
      width: 16px;
      margin-top: -3px;
      path {
        fill: white !important;
      }
    }
    span {
      font-size: 14px;
      font-weight: bold;
      display: flex;
      color: white;

    }
    .tssm-coach-card-num-reviews {
      margin-left:4px;
    }
  }

  .tssm-coach-card-rating.tssm-coach-card-new {
    background-color: var(--style-color-secondary-highlight);
  }
}

.tssm-coach-row-avatar {
  //border: solid #cecece 2px;
  transition: all 0.25s ease-in-out;
  width: 100%;
  height: auto;
}

.tssm-coach-row:hover .tssm-coach-row-avatar {
  //border: solid var(--ts-primary-color) 2px;
}

.tssm-coach-row-avatar-ctn {
  position: relative;
  padding-top: 100%;
  background-color: var(--style-color-shade);
  border-radius: var(--style-border-radius-lg);
  @media only screen and (max-width: 680px) {
    border-radius: var(--style-border-radius-md);
  }

  .tssm-coach-row-avatar,
  .tssm-event-card-bg {
    border-radius: var(--style-border-radius-lg);
    @media only screen and (max-width: 680px) {
      border-radius: var(--style-border-radius-md);
    }
    overflow: hidden;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
    left:0;
    top:0;

  }
}


.tssm-coach-row-inner:hover {
  .tssm-coach-row-avatar {
    //border: solid var(--style-color-primary) 2px;
  }

  .tssm-coach-row-content {
    @media only screen and (min-width: $tssm-md-rule) {
      padding-left: 10px;
      transition: all 0.25s;
    }
  }

  .tssm-coach-row-name {
    color: var(--style-color-primary);
  }
}

.tssm-coach-row-name {
  line-height: 22px;
  font-size: 22px;
  font-weight: 500;
  margin: 0px 0px;
  width: 100%;
}

.tssm-coach-row-rating-ctn {
  display: flex;
  color: var(--ts-primary-color);
  flex-direction: column;
  align-items: end;
  @media only screen and (max-width: $tssm-md-rule) {
    align-items: start;
  }
}
.tssm-coach-row-rating {
  font-size: 16px;
  display: block;
  margin-top: 10px;
}

.tssm-coach-row-reviews-ctn {
  span {
    color: #989898;
    font-size: 14px;
  }
}

.tssm-coach-row-loc-name {
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
  color: #989898;
}

.tssm-coach-row-bio {
  display: flex;
  padding: 10px 0;
  font-size: 14px;
  color: #2c2c2c;
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 0px;
    color: #2c2c2c;
    height: 43px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    max-width: 80%;
    width: 100%;
  }
}

.tssm-coach-row-loc-city-st {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  color: #a2a2a2;
}

.tssm-coach-row-sports {
  color: var(--ts-primary-color);
}

.tssm-coach-row-loc-dist {
  font-size: 12px;
  font-weight: bold;
  margin: 0px;
  color: #a2a2a2;
  span {
    padding: 4px;
  }
}

.tssm-coach-row-teaching-xp-pill {
  margin: 0.5em 1em 0 0;
  background: tan;
  border-radius: 4px;
  color: white;
  padding: 0.5em 1em;
  font-size: 0.875rem;
  display: inline-block;
  text-transform: uppercase;
}

.tssm-coach-row-price {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  flex-direction: column;
  //padding-right: 10px;
}

.tssm-coach-row-price-start {
  margin: 0px;
  font-weight: 300;
  font-size: 12px;
}

.tssm-coach-row-price-rate {
  margin: 0px;
  font-size: 24px;
  font-weight: bold;
}

.tssm-coach-row-price-view-profile {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: capitalize;
  margin: 5px 0px;
  width: 80px;
  padding-left: 0px;
  padding-right: 0px;
  min-width: 140px;
  width: auto;
  border-radius: 14px;
  padding: 14px 20px;
  font-weight: 400;
  transition: all 0.25s ease-in-out;
  border: 2px solid var(--ts-primary-color);
}

//.tssm-coach-row-price-view-profile:hover {
//  background-color: black;
//  border: 2px solid var(--ts-primary-color);
//  color: var(--ts-primary-color);
//}
