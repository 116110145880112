.tssm-search-results {
  .tssm-search-results-events {
    margin-top:20px;
  }
}


// Combined Styles

//.tssm-coaches-browse-ctn, .tssm-events-browse-ctn {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  width: 100%;
//  padding-top: 20px;
//  padding-bottom: 20px;
//  padding-left: 20px;
//  padding-right: 20px;
//}


.tssm-coaches-browse, .tssm-events-browse {
  display: block;
  text-align: center;
  font-weight: 500;
  line-height: 1.75;
  padding: 10px 35px;
}

.tssm-coaches-browse:hover, .tssm-coaches-browse:hover {
  padding:0;

}


// section titles {

.tssm-events-title, .tssm-suggested-locations-title {
  padding: 0 20px;
  font-size: 1.4em;
  font-weight: bold;
  text-transform: uppercase;
}

.btn.tssm-map-results-scroll-to-top-btn {
  background-color:black;
  color:white!important;
  border-radius: var(--style-border-radius-pill)!important;
}
