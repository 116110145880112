.ts-tooltip {
  position: relative;
  .ts-tooltip-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 100px;

    visibility: hidden;
    border-radius: 5px;
    position: absolute;
    top: 110%;
    z-index: 500;

    padding: 10px;
    color: white;
    background-color: var(--style-color-secondary);
    &::after {
      content: ' ';
      position: absolute;
      bottom: 100%; /* At the top of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent var(--style-color-secondary)
        transparent;
    }
  }
  &:hover .ts-tooltip-text {
    visibility: visible;
  }
}
