/* ts- prefixes are intended to be global and project agnostic. */

$ts-sm-rule: 480px;
$ts-md-rule: 720px;
$ts-lg-rule: 920px;
$ts-xl-rule: 1080px;

//@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

/* Style defaults */
.ts {
  //font-size: 18px;
  // font-family: Arial, Helvetica, sans-serif !important;
  //font-family: 'Oswald', sans-serif;
}

//@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);

.ts {
  //font-family: 'Open Sans', sans-serif;
}

.ts p {
  /* You specify the number you see next to the fonts to change from light to bold ie you would put 700 etc. etc. */
}

// Containers //

.ts-container {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
}

// transitions

.transition-all-3 {
  transition: all 0.3s;
}

.ts-hide-me {
  display: none !important;
}

@import './card.scss';
@import './btn.scss';
@import './input.scss';
@import './divider.scss';
@import './tooltip.scss';
