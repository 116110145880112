.tssm-event-row{
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid #dddddd;
}

.tssm-event-row-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  width: 100%;

  @media only screen and (max-width: $tssm-md-rule) {
    flex-direction: column;
  }


  &:hover {
    .tssm-event-row-content {
      padding-left: 10px;
      transition: all 0.25s;
    }
  }
}

.tssm-event-row-cv-text {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  width: 75%;

  @media only screen and (max-width: $tssm-md-rule) {
    flex-direction: column;
    width:100%;
  }
}

.tssm-event-row-cover-ctn {
  width: 160px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  min-width: 160px;
  height: 120px;
  background: whitesmoke;
  border-radius: 15px;
  @media only screen and (max-width: $tssm-md-rule) {
    height:25%;
    width: 100%;
  }
}

.tssm-event-row-cover {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.tssm-event-row-content {
  width: 100%;
  position: relative;
  padding-left: 0px;
  transition: all 0.25s ease-in-out;
}

.tssm-event-row-date {
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  color: #A2A2A2;
  margin: 4px 0px;
}

.tssm-event-row-date-title {
  font-weight: bold;
}

.tssm-event-row-name {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0px;
}

.tssm-event-row-loc {
  font-size: 12px;
  color: var(--style-color-gray);
}

.tssm-event-row-price {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 25%;
  padding-right: 20px;
  @media only screen and (max-width: $tssm-md-rule) {
    width: 100%;
    flex-direction: column;
    height: auto;
    align-items: start;
  }
}

.tssm-event-row-price-start {
  margin: 0px;
  font-weight: 300;
  font-size: 12px;
}

.tssm-event-row-price-rate {
  margin: 0px;
  font-size: 24px;
  font-weight: bold;
}

.tssm-event-row-price-view-booking {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: capitalize;
  margin: 5px 0px;
  width: 80px;
  padding-left: 0px;
  padding-right: 0px;
  min-width: 140px;
  width: auto;
  border-radius: var(--ts-border-radius-md);
  padding: 14px 20px;
  font-weight: 400;
  transition: all 0.25s ease-in-out;
  border: 2px solid var(--ts-primary-color);
}
