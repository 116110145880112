.tssm-coach-card {
  .tsb-profile-sports {
    padding: 0;
    line-height: 1em !important;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: normal;
  }
}

.tssm-coach-card-link-wrapper {
  color: var(--tssm-text-primary) !important;
  text-decoration: inherit;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: block;
  margin-bottom: 15px;
}

.tssm-coach-card-inner {
  // width: 100%;

  text-align: center;

  border: none;
  height: 100%;
}

.tssm-coach-card {
  > .tssm-coach-card-inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    @media only screen and (max-width: 600px) {
      //flex-direction: row;
      > a {
        width: 100%;
      }

      .tssm-coach-card-content {
        //min-width: 70%;
      }
    }
    @media only screen and (max-width: 400px) {
      flex-direction: column;
    }
  }
}

.tssm-coach-card-image {
  background-color: whitesmoke;
}

.tssm-coach-card-bg {
  height: 160px;
  margin: 0px;
  padding: 0px;
  border: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: none;
}

.tssm-coach-card-av {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  margin-top: -40px;
}

.tssm-coach-card-av-inner {
  border-radius: 200px !important;
  width: 136px;
  height: 136px;
  margin-left: auto;
  margin-right: auto;
  //transform: translate(0, -40px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 2px solid white;
  transition: all 0.25s ease-in-out;
  background-color: whitesmoke;
}

.tssm-coach-card-content {
  padding: 20px 10px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > a {
    width: 100%;
  }
}

.tssm-coach-card-av-inner {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.tssm-coach-card:hover {
  .tssm-coach-card-av-inner {
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.tssm-coach-card-rating {
  font-weight: bold;
  display: flex;
  z-index: 2;
}

.tssm-coach-card-rating-inner {
  font-size: 16px;
  margin-left: 5px;
}

.tssm-coach-card-row-availability {
  margin-bottom: 4px;
}

.tssm-coach-card-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .tssm-coach-card-row-inner {
    display: flex;
    justify-content: center;
    .tssm-coach-card-icon {
      margin-right: 10px;
      svg {
        width: 15px;
        height: auto;
      }
    }
  }

  .tssm-coach-card-booking-link {
    color: var(--style-color-primary);
    font-size: 14px;
  }
}

.tssm-coach-card-row-location {
  font-size: 14px;
  color: #81818a;
  justify-content: flex-start;
  text-align: left;
  .tssm-coach-card-loc-dist {
    font-size: 14px;
    margin-left: 4px;
    color: var(--style-color-primary);
  }
}

.tssm-coach-card-row-sports {
  .tsb-profile-sports {
    margin-top: 8px;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }
}

.tssm-coach-card-row-rate,
.tssm-coach-card-row-availability {
  .tssm-coach-card-row-inner {
    h6 {
      margin-bottom: 0;
      margin-top: 4px;
      text-align: left;
    }
  }
}

.tssm-coach-card-row-bio {
  display: flex;
  align-items: center;
  border-top: 1px solid #f5f5fc;
  margin-top: 8px;
  text-align: left;

  .tssm-coach-card-minibio {
    font-size: 12px;
    text-align: left;
    margin-top: 8px;
    max-height: 35px;
    overflow: hidden;

    p {
      margin: 0;
    }
  }
  i {
    font-size: 16px;
    color: var(--style-color-secondary);
  }

  .tssm-coach-card-bio-popover {
    font-size: 0.875em;
    margin-bottom: 0;
  }

  .tssm-popover-show-more {
    padding-left: 5px;
    text-decoration: underline;
  }

  .icon-more-info {
    margin-left: 6px;
  }
}

.tssm-coach-card-name {
  font-weight: bold;
  font-size: 22px;
  line-height: 21px;
  margin: 0px;
  margin-bottom: 0;
  margin-top: 0px;
  color: var(--tssm-text-primary);
  text-align: left;
}

.tssm-coach-card-loc-name {
  margin: 0px;
  margin-top: 4px;
  opacity: 0.5;
  text-align: left;
  width: 70%;
}

.tssm-coach-card-loc-city-st {
  margin: 0px;
  opacity: 0.5;
  font-size: 0.8em;
}

.tssm-coach-card-loc-dist {
  margin: 0px;
  color: #c7c7c7;
}
.tssm-coach-card-num-reviews {
  opacity: 0.5;
}

.tssm-coach-card-see-btn-ctn {
  display: flex;
  justify-content: end;
  align-items: center;
}

.tssm-coach-card-see-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.tssm-coach-card-see-btn-icon {
  margin-left: 3px;
  font-size: 18px;
}

.tssm-coach-card-bio-popover-ctn {
  .btn {
    width: 100%;
  }

  .tssm-coach-card-pop-tagline {
    color: var(--style-color-primary);
  }

  .tssm-coach-card-pop-name {
    margin-bottom: 10px;
    margin-top: 0;
    font-weight: bold;
  }

  p {
    color: black;
  }

  a {
    width: 100%;
  }

  .tssm-popover-show-more {
    border: 1px solid gray;
    border-radius: var(--style-border-radius-pill) !important;
  }
}
