@font-face {
  font-family: 'tsi';
  src: url('./tsi.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='tsi-'],
[class*=' tsi-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tsi' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tsi-link:before {
  content: '\e912';
}
.tsi-volume:before {
  content: '\e911';
}
.tsi-nolocation:before {
  content: '\e915';
}
.tsi-location:before {
  content: '\e916';
}
.tsi-cancel:before {
  content: '\e913';
}
.tsi-search:before {
  content: '\e914';
}
.tsi-replay:before {
  content: '\e910';
}
.tsi-play:before {
  content: '\e90f';
}
.tsi-pause:before {
  content: '\e90e';
}
.tsi-arrow-d:before {
  content: '\e90a';
}
.tsi-arrow-l:before {
  content: '\e90b';
}
.tsi-arrow-r:before {
  content: '\e90c';
}
.tsi-arrow-u:before {
  content: '\e90d';
}
.tsi-controls:before {
  content: '\e909';
}
.tsi-msg:before {
  content: '\e907';
}
.tsi-thriv:before {
  content: '\e908';
}
.tsi-star:before {
  content: '\e904';
}
.tsi-ticket:before {
  content: '\e905';
}
.tsi-tickets:before {
  content: '\e906';
}
.tsi-chevron-d:before {
  content: '\e900';
}
.tsi-chevron-l:before {
  content: '\e901';
}
.tsi-chevron-r:before {
  content: '\e902';
}
.tsi-chevron-u:before {
  content: '\e903';
}
