.retrying-container {
  background: lightgray;
  border-radius: 20px;
  min-width: 80px;
  height: 80px;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}
