.tssm-event-tab{
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  min-width: 320px;
  width: 50%;
  color: inherit;
  text-decoration: inherit;
}

.tssm-event-tab-content {
  padding-top: 15px;
}

.tssm-event-tab-cv-text {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 5px;
}

.tssm-event-tab-cover-ctn {
  width: 128px;
  height: 128px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.tssm-event-tab-cover {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.tssm-event-tab-date {
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  color: #A2A2A2;
  margin: 15px 0px;
}

.tssm-event-tab-date-title {
  font-weight: bold;
}

.tssm-event-tab-name {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0px;
}

.tssm-event-tab-loc {
  font-size: 12px;
  color: var(--style-color-primary);
}

.tssm-event-tab-price {
  position: absolute;
  top: 10px;
  right: 10px;
}

.tssm-event-tab-price-rate {
  margin: 0px;
  font-size: 20px;
  font-weight: bold;
}

.tssm-event-tab-price-view-booking {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  margin: 5px 0px;
}
