.ts-link {
  color: var(--ts-primary-color);
  background-color: transparent;
  font-family: 'gravesend-sans', sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
}

.ts-link:visited {
  color: var(--ts-primary-color);
}

.ts-link-icon::before {
  display: inline-block;
  margin-left: 0.1em;
  transform: rotate(30deg) translate(0.1em, 0px);
}

.ts-link-text {
  color: inherit !important;
}

.ts-link-image-wrap {
  width: auto;
  height: 100%;
}

.ts-link-item {
  color: var(--ts-primary-color) !important;
  text-decoration: none;
  -webkit-appearance: none;
  background-color: transparent !important;
  background: transparent !important;
  border: 0;
  padding: 4px;
}
