.ts-carousel {
  width: 100%;
  max-width: 50vw;
  overflow: hidden;
}

.ts-carousel-items {
  display: flex;
  gap: 10px;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.ts-carousel-item {
  scroll-snap-align: center;
  // position: relative;
  // transform: perspective(500px) translateZ(-200px);
  // transition: transform 1s;
  transform: perspective(500px);
}

.ts-carousel-item.active {
  // transform: perspective(0px) translateZ(0px);
  // transition: transform 1s;
}

.ts-carousel-item.precede {
  
}

.ts-carousel-item.procede {

}

.ts-carousel-item:first-child {
  margin-left: 50%;
  // transform: rotate3d(0, 1, 0, 60deg);
}

.ts-carousel-item:last-child {
  margin-right: 50%;
}

.ts-carousel-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.ts-carousel-nav-item {
  font-size: 28px;
  cursor: pointer;
}

.ts-carousel-nav-item.active {
  color: var(--ts-primary-color);
  font-size: 38px;
  transform: translateZ(0px);
}

.ts-active-scrolling {
  scroll-snap-type: none;
  scroll-behavior: auto;
  // scroll-snap-type: x proximity;
}