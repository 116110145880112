.tssm-coach-tab {
  color: inherit;
  text-decoration: inherit;
  min-width: 320px;
  width: 50%;
}

.tssm-coach-tab-av-text {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  position: relative;
}

.tssm-coach-tab-avatar-ctn {
  width: 100px;
  margin: 0px;
}

.tssm-coach-tab-avatar {
  border-radius: 50%;
  height: 100px;
  border: solid #0000 2px;
  transition: border 0.5s;
}

.tssm-coach-tab:hover .tssm-coach-tab-avatar{
  border: solid var(--ts-primary-color) 2px;
  transition: border 0.25s;
}

.tssm-coach-tab-name {
  // line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0px;
}

.tssm-coach-tab-rating {
  font-size: 12px;
  margin: 0px;
  margin-bottom: 3px;
  display: block;
}

.tssm-coach-tab-loc {

}

.tssm-coach-tab-loc-name {
  font-size: 12px;
  font-weight: bold;
  margin: 0px;
}

.tssm-coach-tab-loc-city-st {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  color: #A2A2A2;
}

.tssm-coach-tab-loc-dist {
  font-size: 12px;
  font-weight: bold;
  margin: 0px;
  color: #A2A2A2;
}

.tssm-coach-tab-price {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  flex-direction: column;
}

.tssm-coach-tab-price-start {
  margin: 0px;
  font-weight: 300;
  font-size: 12px;
}

.tssm-coach-tab-price-rate {
  margin: 0px;
  font-size: 20px;
  font-weight: bold;
}

.tssm-coach-tab-price-view-profile {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  margin: 5px 0px;
}