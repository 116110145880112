.react-form-group {
  p {
    font-size: 14px;
  }
  .fileselector {
    .file-choose-btn {
      display: block;
    }
    .hint {
      width: 100%;
      text-align: center;
      float: left;
      font-size: 16px;
    }
    .preview {
      img {
        display: block;
        max-width: 100%;
      }
    }
  }
}
