.tssm-coaches {
  width: 100%;
  float:left;
}

.tssm-suggested-locations {
  float:left;
  width:100%;
}

.tssm-coaches-title {
  font-size: 24px;
  font-weight: bold;
}

.tssm-coaches-title-num {
  color: var(--style-color-primary);
}

.thriv-homepage {
  .tssm-coaches-ctn {
    margin-top: 40px;
  }
}
.discover-search {

}

.tssm-coaches-ctn {
  float: left;
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  padding: 0px;
  z-index: 1;
  position: relative;

  .tssm-coach-card {
    width: 25%;
    padding:12px;
    margin-bottom: 50px;
    @media only screen and (max-width: 998px) {
      width: 33.3%;
      margin-bottom: 25px;

    }
    @media only screen and (max-width: 768px) {
      width: 50%;
      margin: 0px;
      margin-bottom: 25px;
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }




}


.tssm-coaches-not-found {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .tssm-coaches-browse {
    margin-left: 0px;
  }

  .tssm-coaches-not-found-txt {
    color: var(--style-color-primary);
    text-align:center;
  }

  svg {
    margin-right: 20px;
  }
}

.tssm-coaches-browse-divider {

}

