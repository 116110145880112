.ts-modal-ctn {

  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0 0 0 / 77%);
  opacity: 0;
  transition: all 0.5s ease;
  backdrop-filter: blur(15px) brightness(1.2);
  -webkit-backdrop-filter: blur(5px) brightness(1.2);
  visibility: hidden;
}

.ts-modal-scroll {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  overflow-y: auto;
  padding:0;
  > .ts-modal {
    padding:0;
  }
}

.ts-modal-ctn.active {
  visibility: visible;
  opacity: 1;
}


.tsp-modal-media-ctn,.tsb-tout-modal-media-ctn {
  animation-delay: 1s;
}


.ts-modal {
  display: flex;

  width: 100%;

  &::-webkit-scrollbar-track {
    background-color: #0000;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5b5b5;
  }

  &::-webkit-scrollbar-thumb:hover {
  }

  transition: all 0.25s ease-in-out;
  top:100px;
  opacity: 0;
  animation-delay: 2s;
  position: relative;
}



.ts-btn.tsb-modal-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 20px;
  right:auto;
  margin: 0px;
  font-size: 16px;
  transition: all 0.25s ease-in-out;
  top: -100px;
  opacity: 0;
  animation-delay: .5s;
  transition-delay: .5s;
  color:white;
  border-radius: 8px;
  padding: 6px 14px;
  i {
    padding-right:6px;
    font-size:12px;
  }
  &:hover {
    background-color: #4a4a4a;
    color:white;
  }
}

.active {
  .ts-modal {
    opacity: 1;
    top:0;
  }

  .tsb-modal-close-btn {
    top:10px;
    opacity: 1;
  }
}

.tsb-modal-content-ctn {
  padding:10px;
}

.tsp-modal-footer {
  .ts-btn {
    .ts-link-icon {
      display:none;
    }
  }

  .ts-btn{
    color: var(--style-color-primary)
  }
}
