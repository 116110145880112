.tssm-map-results {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
}

.tssm-map-results-type {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
}

.tssm-map-results-type {
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.tssm-map-results-type-btn {
  min-width: 100px;
  cursor: pointer !important;
  outline: 0 !important;
  @media only screen and (max-width: 600px) {
    width: 50%;
  }
}

.tssm-map-results-type-btn:hover {
  color: #a2a2a2;
}

.tssm-map-results-type-btn.active {
  cursor: pointer;
}

.tssm-landing-header-location-results {
  display: inline;
}

.tssm-map-results-result-num,
.tssm-landing-header-location-results {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
  padding: 8px 0;
  margin-right: 10px;
}

.map-control-btn {
  display: none;
}

.tssm-map-results-radius-sel,
.tssm-map-preview-radius-sel {
  padding: 0;
  //color: var(--style-color-primary);
  margin-right: 15px;
  .ts-sel {
    padding: 0;
  }
  .ts-selector-items {
    width: auto;

    border: 0;
    min-width: 120px;
    padding: 20px;
  }
  .ts-selector-icon {
    font-size: 0.8em;
    border-radius: 100px;
    padding: 5px;
  }
}

.tssm-map-results-radius-sel-items {
  margin: 20px 0px;
  font-size: 1.2em;
}

.tssm-map-results-loc {
  font-size: 24px;
  margin: 0px;
}

.tssm-map-results-list {
  width: 100%;
  height: auto;
}

.tssm-map-results-list.disabled {
}

.tssm-map-results-list.active {
}

.tssm-map-results-result-title-sport {
  color: var(--style-color-primary);
  font-weight: 500;
  font-size: 20px;
}

.tssm-map-results-list-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px 0;
  position: relative;
  z-index: 100;
  //margin-top:60px;

  @media only screen and (max-width: $tssm-xl-rule) {
  }

  @media only screen and (max-width: $tssm-md-rule) {
    flex-direction: column;
  }

  .tssm-map-results-list-heading-left {
    justify-content: flex-start;
    width: 100%;
    align-items: center;
  }

  .tssm-map-results-list-heading-right {
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: flex-start;
    }
  }
}

.tssm-map-results-result-title-location,
.tssm-landing-header-location {
  font-size: 2em;
  font-weight: bold;
  line-height: 1;
  @media only screen and (max-width: $tssm-md-rule) {
    font-size: 1.6em;
    margin-bottom: 2px;
  }
}

.tssm-map-results-list-items {
  display: flex;
  // justify-content: center;
  // align-items: flex-start;
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.tssm-map-results-list-items-inner {
  background-color: White;
  border-radius: 15px;
  @extend .tssm-container-shadows;
  width: 100%;
}

.tssm-map-results-list-no-res {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 40px 20px;
  p {
    margin: 0px;
  }
}

.tssm-map-results-list-no-res-title {
  font-size: 24px;
  font-weight: bold;
}

.tssm-map-results-list-no-res-txt {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.tssm-map-results-list-no-res-sub {
  font-size: 14px;
}

.tssm-map-results-map {
  display: none;
  position: relative;
  padding: 10px 0;
  @media only screen and (max-width: $tssm-md-rule) {
    display: none;
  }

  .tssm-map-results-map-inner {
    > div {
      outline: 0;
    }
  }
}

.tssm-map-results-map.disabled {
  overflow: hidden;

  visibility: hidden;
}

.tssm-map-results-map.active {
  visibility: visible;
}

.tssm-map-results-search-here {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 900;
  width: 100%;
  padding-top: 10px;
}

.tssm-map-results-search-here-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white !important;
  width: 120px;
  text-align: center;

  .tsi-location {
    font-size: 18px;
    margin-right: 4px;
  }
}

.tssm-map-results-sel-loc {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  z-index: 900;
  width: 100%;
  margin: 10px;
}

.tssm-map-results-sel-loc-title {
  // width: 100%;
  margin: 10px 0px;
}

.tssm-map-results-sel-loc-name {
  font-weight: bold;
}
.tssm-map-results-sel-loc-addr {
  color: #a2a2a2;
  font-size: 14px;
}
.tssm-map-results-sel-loc-city {
  color: #a2a2a2;
  font-size: 14px;
}
.tssm-map-results-sel-loc-state {
  color: #a2a2a2;
  font-size: 14px;
}
.tssm-map-results-sel-loc-country {
  color: #a2a2a2;
  font-size: 14px;
}

.tssm-map-results-sel-loc-scroll {
  width: calc(100% - 20px);
  display: flex;
  justify-content: start;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;
}

.tssm-map-results-mobile-mode {
  display: none;
}

.tssm-map-results-mobile-mode.active {
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  z-index: 999;
}

.tssm-map-results-mobile-mode-btn {
  background-color: black;
  border-radius: 20px;
  font-size: 26px;
}

.tssm-map-results-bottom {
  text-align: center;
  padding-top: 1em;
}
