/** 
 * This is used in many places and generally styled.
 * To customize, use the containerStyle Popover prop
 * (See CoachCard.tsx in the search project for an example)
 */
.react-tiny-popover-container {
  background-color: white;
  opacity: 1;
  border-radius: var(--style-border-radius-md);
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.4);
  max-width: 400px;
  text-align: left;
  z-index: 240;
  position: relative;
  padding: 15px;
  > p {
    line-height: 1.2em;
    color:black;
  }


  /* This is the element that triggers the hover state, so the padding must be applied here */
  .hover-popover-content {
    padding: 15px;
  }
  .ts-link {
    color: var(--style-color-primary)
  }
}
