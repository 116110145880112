.tssm-map-preview-container {
  float:left;
  width:100%;
  margin-bottom:10px;
}
.tssm-map-preview {
  > div {
    border-radius: 10px;
    overflow: hidden;
  }

}

.tssm-map-preview-title {
  margin: 10px 0px;
  font-size: 30px;
  letter-spacing: 2px;
}

.tssm-map-preview-result-num {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
}

.tssm-map-preview-result-num-highlight {
  color: var(--style-color-primary);
}




.tssm-map-preview-radius-sel {
  color: var(--style-color-primary);
  
  .ts-selector-items {
    width: auto!important;
  }
  .ts-selector-icon {
    content: '';
  }
}

.tssm-landing-header {
  .tssm-map-preview-result-num-highlight {
    color: white;
    padding: 0 4px;
  }

  .tssm-map-preview-radius-sel {

    .ts-selector-title {
      color: #ff95a7 !important;
    }
  }

  .ts-sel {
    padding: 0;
    padding-left: 8px;
    padding-right: 24px;
  }
  .tssm-map-preview-radius-sel .ts-selector-icon {
    color: #ff95a7 !important;
  }

  .ts-selector-items {
    z-index: 4000;
  }

  .tssm-landing-header-subtitle {
    display: flex;
  }
}

.tssm-map-preview-radius-sel-items {
  margin: 10px 0px;
}
