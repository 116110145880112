.tssm-event-card {
  width: 100%;
}

.tssm-event-card-link-wrapper {
  color: inherit;
  text-decoration: inherit;
}

.tssm-event-card-inner {

  padding: 0px;
  margin: 10px;
  //overflow: hidden;

  border: none;
  position: relative;
  height:100%;

  .tssm-event-card-bg {

  }

  p {
    margin: 0;
    margin-bottom: 10px;
  }

  .tssm-event-card-info {
    transition: all 0.3s;
    -moz-transform: translate(0px, 0);
    -webkit-transform: translate(0px, 0);
    -o-transform: translate(0px, 0);
    -ms-transform: translate(0px, 0);
    transform: translate(0px, 0);
  }


  &:hover {
    .tssm-event-card-info {
      -moz-transform: translate(0px, -14px);
      -webkit-transform: translate(0px, -14px);
      -o-transform: translate(0px, -14px);
      -ms-transform: translate(0px, -14px);
      transform: translate(0px, -14px);
    }
  }
}

.tssm-event-card-bg {
  height: 200px;
  margin: 0px;
  padding: 0px;
  border: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: $tssm-md-rule) {
    height: 280px;
  }

}
.tssm-event-card-content {
  padding:10px;
  position:relative;
}

.tssm-event-card-left {
  width:75%;
}

.tssm-event-card-right {

  .tssm-event-card-date-circle {
    position:absolute;
    background: var(--style-color-primary);
    background-color: var(--style-color-primary);
    top: -30px;
    width:auto;
    right: 20px;
    text-align:center;
    display:flex;
    flex-direction: column;
    border-radius:100px;
    padding:4px;
    width:60px;
    height:60px;
    justify-content: center;

    .tssm-event-card-date-day {
      font-size:1.4em;
      color: white;
    }

    .tssm-event-card-date-month {
      color:white;
      font-size:.8em;

    }
  }

}


.tssm-event-card-info {
  padding: 5px;
}

.tssm-event-card-title {
  font-weight: bold;
  font-size: 18px;
  color: var(--tssm-text-primary);
}

.tssm-event-card-date {
  font-size: 12px;
  color: gray;
}

.tssm-event-card-date-title {
  font-weight: bold;
}

.tssm-event-card-tickets {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  color: var(--style-color-primary);

  .tsi-ticket {
    font-size: 18px;
    transform: translate(0px, 2px) rotate(45deg);
    margin-right: 8px;
    display:none;
  }
}

.tssm-event-card-tickets-available {
  font-weight: bold;
}

.tssm-event-card-see-btn-ctn {
  display: flex;
  justify-content: end;
  align-items: center;
}

.tssm-event-card-see-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-right: 0px;
}

.tssm-event-card-see-btn-icon {
  margin-left: 3px;
  font-size: 18px;
}
