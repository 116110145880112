.ts-slider-ctn {
  
}

.ts-slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  background: var(--ts-border-color);
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 5px;
  cursor: pointer;
}

.ts-slider:hover {
  opacity: 1;
}

.ts-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--ts-primary-color);
  cursor: pointer;
  border-radius: 50%;
}
.ts-slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--ts-primary-color);
  cursor: pointer;
  border-radius: 50%;
}