.ts-btn {
  text-decoration: none;
  color: var(--ts-text-secondary);
  padding: 10px;
  filter: brightness(100%);
  transition: filter 0.2s;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  display:flex;
  justify-content: center;
  align-items: center;
  .tsi-icon-ctn {
    font-size:1.2em;
    margin-right:4px;
  }
}

.ts-btn:hover {
  filter: brightness(110%);
  transition: filter 1s;
  color: var(--ts-text-secondary);
}

.ts-btn:active:hover {
  filter: brightness(90%);
  transition: filter 0s;
  color: var(--ts-text-secondary);
}

.ts-btn.disabled {
  filter: grayscale(80%);
  cursor:inherit;
}

.ts-btn.disabled:active:hover {
  filter: grayscale(80%);
  cursor: inherit;
}

.ts-btn-muted {
  background-color:black;
  color:white;
}
